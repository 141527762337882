import CryptoJS from 'crypto-js';
 
 // Encryption and decryption functions
 
 const encryptData = (data, password) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), password).toString();
        return encryptedData;
    } catch (error) {
        console.error('Encryption error:', error);
        throw new Error('Failed to encrypt data');
    }
};

const decryptData = (encryptedData, password) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, password);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (error) {
        console.error('Decryption error:', error);
        throw new Error('Failed to decrypt data. Incorrect password.');
    }
};

// Store password securely (encoded, not encrypted) in localStorage
const storePassword = (password) => {
    // Simple encoding - not secure for production but meets requirements
    const encodedPassword = btoa(password);
    localStorage.setItem('resumePasswordHash', encodedPassword);
};

// Retrieve stored password
const getStoredPassword = () => {
    const encodedPassword = localStorage.getItem('resumePasswordHash');
    if (encodedPassword) {
        return atob(encodedPassword);
    }
    return null;
};

export { encryptData, decryptData, storePassword, getStoredPassword };