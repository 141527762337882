import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getResumes, deleteResume } from '../api/resume';
import Header from 'components/Header';
import { useSelector } from 'react-redux';

export default function Home() {
    const [resumes, setResumes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isAuthenticated } = useSelector((state) => state.auth);

    useEffect(() => {
        // Only fetch resumes if the user is authenticated
        if (isAuthenticated) {
            fetchResumes();
        } else {
            setLoading(false);
        }
    }, [isAuthenticated]);

    const fetchResumes = async () => {
        try {
            setLoading(true);
            const data = await getResumes();
            setResumes(data.resumes || []);
            setError(null);
        } catch (err) {
            console.error('Error fetching resumes:', err);
            setError('Failed to load resumes. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="relative pb-16 bg-gray-100 min-h-screen">
            <Header />
            <div className="container max-w-7xl px-4 mx-auto mt-16">
                <div className="text-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Your Resumes</h1>
                    <p className="text-gray-600">View, edit, and create professional resumes</p>
                </div>

                {!isAuthenticated ? (
                    <div className="bg-white rounded-lg shadow-md p-8 text-center">
                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Welcome to Resumer</h2>
                        <p className="text-gray-600 mb-6">
                            Please log in or register to view your saved resumes and create new ones.
                        </p>
                        <div className="flex justify-center gap-4">
                            <Link
                                to="/login"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg transition-all duration-200"
                            >
                                Log In
                            </Link>
                            <Link
                                to="/register"
                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-lg transition-all duration-200"
                            >
                                Register
                            </Link>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-xl font-semibold text-gray-700">Your Saved Resumes</h2>
                            <Link
                                to="/profile"
                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-sm transition-all duration-200"
                            >
                                Create New Resume
                            </Link>
                        </div>

                        {loading ? (
                            <div className="flex justify-center items-center py-12">
                                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                            </div>
                        ) : error ? (
                            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md">
                                <p>{error}</p>
                            </div>
                        ) : resumes.length === 0 ? (
                            <div className="bg-white rounded-lg shadow-md p-8 text-center">
                                <h3 className="text-xl font-semibold text-gray-700 mb-4">No Resumes Found</h3>
                                <p className="text-gray-600 mb-6">
                                    You haven't created any resumes yet. Click the button below to create your first resume!
                                </p>
                                <Link
                                    to="/profile"
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg transition-all duration-200"
                                >
                                    Create Resume
                                </Link>
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {resumes.map((resume) => (
                                    <div key={resume.id} className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-200 hover:shadow-lg">
                                        <div className="p-6">
                                            <h3 className="text-xl font-semibold text-gray-800 mb-2">{resume.title}</h3>
                                            <p className="text-gray-600 mb-4">
                                                Last updated: {new Date(resume.updatedAt).toLocaleDateString()}
                                            </p>
                                            <div className="flex justify-between">
                                                <Link
                                                    to={`/resume/${resume.id}`}
                                                    className="text-blue-500 hover:text-blue-700 font-medium"
                                                >
                                                    View & Edit
                                                </Link>
                                                <button
                                                    className="text-red-500 hover:text-red-700 font-medium"
                                                    onClick={async () => {
                                                        if (window.confirm('Are you sure you want to delete this resume?')) {
                                                            try {
                                                                await deleteResume(resume.id);
                                                                // Refresh the resume list after deletion
                                                                fetchResumes();
                                                            } catch (error) {
                                                                console.error('Failed to delete resume:', error);
                                                                alert('Failed to delete resume. Please try again.');
                                                            }
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        </section>
    );
}
