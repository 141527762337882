import { getAuthToken } from './auth';
import CryptoJS from 'crypto-js';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080/api';

// Save a resume
export const saveResume = async (title, data, password, resumeId) => {
    try {
        const token = getAuthToken();
        
        if (!token) {
            throw new Error('No authentication token found');
        }
        
        // Encrypt the data
        const encryptedData = encryptData(data, password);

        if (resumeId) { 
            console.log('update the resume using PUT');
            console.log('resumeId', resumeId);
            console.log('title', title);
            console.log('data', encryptedData);
            // update the resume using PUT 
            const response = await fetch(`${API_URL}/resumes/${resumeId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    title,
                    encryptedData
                })
            });
        
            if (!response.ok) {
                // Handle specific error cases
                if (response.status === 401) {
                    // Token is invalid or expired
                    throw new Error('Invalid token');
                }
                throw new Error('Failed to update resume');
            }
        
            return await response.json();
        }
        
        const response = await fetch(`${API_URL}/resumes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                title,
                encryptedData
            })
        });
        
        if (!response.ok) {
            // Handle specific error cases
            if (response.status === 401) {
                // Token is invalid or expired
                localStorage.removeItem('authToken'); // Clear the invalid token
                throw new Error('Your session has expired. Please sign in again.');
            }
            throw new Error(`Server responded with status: ${response.status}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error saving resume:', error);
        throw error;
    }
};

// Get all resumes for the current user
export const getResumes = async () => {
    try {
        const token = getAuthToken();
        
        if (!token) {
            throw new Error('No authentication token found');
        }
        
        const response = await fetch(`${API_URL}/resumes`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            // Handle specific error cases
            if (response.status === 401) {
                // Token is invalid or expired
                localStorage.removeItem('authToken'); // Clear the invalid token
                throw new Error('Your session has expired. Please sign in again.');
            }
            throw new Error(`Server responded with status: ${response.status}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error getting resumes:', error);
        throw error;
    }
};

// Get a specific resume by ID
export const getResumeById = async (resumeId) => {
    try {
        const token = getAuthToken();
        
        if (!token) {
            throw new Error('No authentication token found');
        }
        
        const response = await fetch(`${API_URL}/resumes/${resumeId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            // Handle specific error cases
            if (response.status === 401) {
                // Token is invalid or expired
                localStorage.removeItem('authToken'); // Clear the invalid token
                throw new Error('Your session has expired. Please sign in again.');
            }
            if (response.status === 404) {
                throw new Error('Resume not found');
            }
            throw new Error(`Server responded with status: ${response.status}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error getting resume by ID:', error);
        throw error;
    }
};

// Decrypt a resume
export const decryptResume = (encryptedData, password) => {
    try {
        return decryptData(encryptedData, password);
    } catch (error) {
        console.error('Error decrypting resume:', error);
        throw error;
    }
};

// Update an existing resume by ID
export const updateResume = async (resumeId, title, data, password) => {
    try {
        const token = getAuthToken();
        
        if (!token) {
            throw new Error('No authentication token found');
        }
        
        // Encrypt the data
        const encryptedData = encryptData(data, password);
        
        const response = await fetch(`${API_URL}/resumes/${resumeId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                title,
                encryptedData
            })
        });
        
        if (!response.ok) {
            // Handle specific error cases
            if (response.status === 401) {
                // Token is invalid or expired
                localStorage.removeItem('authToken'); // Clear the invalid token
                throw new Error('Your session has expired. Please sign in again.');
            }
            if (response.status === 404) {
                throw new Error('Resume not found');
            }
            throw new Error(`Server responded with status: ${response.status}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error updating resume:', error);
        throw error;
    }
};

// Encryption and decryption functions
const encryptData = (data, password) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), password).toString();
        return encryptedData;
    } catch (error) {
        console.error('Encryption error:', error);
        throw new Error('Failed to encrypt data');
    }
};

const decryptData = (encryptedData, password) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, password);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (error) {
        console.error('Decryption error:', error);
        throw new Error('Failed to decrypt data. Incorrect password.');
    }
};

// Delete a resume by ID
export const deleteResume = async (resumeId) => {
    try {
        const token = getAuthToken();
        
        if (!token) {
            throw new Error('No authentication token found');
        }
        
        const response = await fetch(`${API_URL}/resumes/${resumeId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            // Handle specific error cases
            if (response.status === 401) {
                // Token is invalid or expired
                localStorage.removeItem('authToken'); // Clear the invalid token
                throw new Error('Your session has expired. Please sign in again.');
            }
            throw new Error(`Server responded with status: ${response.status}`);
        }
        
        return true; // Successfully deleted
    } catch (error) {
        console.error('Error deleting resume:', error);
        throw error;
    }
};
