import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout, setUser } from '../features/authSlice';
import { auth } from '../firebase';
import { useEffect } from 'react';

export default function Header() {
    const dispatch = useDispatch();
    const { user, isAuthenticated } = useSelector((state) => state.auth);

    // Add this useEffect to check auth state on component mount
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                // User is signed in, dispatch the user data to Redux
                dispatch(setUser({
                    uid: currentUser.uid,
                    email: currentUser.email,
                    displayName: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                }));
            } else {
                // User is signed out
                dispatch(logout());
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [dispatch]);

    const handleLogout = async () => {
        try {
            await auth.signOut();
            dispatch(logout());
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    // Rest of the component remains the same
    return (
        <header className="bg-white shadow-md">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                <div className="flex justify-between items-center">
                    <Link to="/">
                        <h1 className="text-2xl font-bold text-blue-700">Resumer</h1>
                    </Link>
                    
                    <div className="flex items-center space-x-4">
                        {isAuthenticated ? (
                            <>
                                <div className="flex items-center space-x-2">
                                    {user?.photoURL && (
                                        <img 
                                            src={user.photoURL} 
                                            alt="Profile" 
                                            className="w-8 h-8 rounded-full"
                                        />
                                    )}
                                    <span className="text-gray-700">{user?.displayName}</span>
                                </div>
                                <button
                                    onClick={handleLogout}
                                    className="text-blue-600 hover:text-blue-800"
                                >
                                    Logout
                                </button>
                            </>
                        ) : (
                            <>
                                <Link to="/login" className="text-blue-600 hover:text-blue-800">
                                    Login
                                </Link>
                                <Link to="/register" className="text-blue-600 hover:text-blue-800">
                                    Register
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}