import { auth } from '../firebase';
import { 
    signInWithPopup, 
    GoogleAuthProvider, 
    FacebookAuthProvider,
    signOut as firebaseSignOut
} from "firebase/auth";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080/api';

// Verify token with the server and get user data
export const verifyToken = async (idToken) => {
    try {
        const response = await fetch(`${API_URL}/auth/verify-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idToken })
        });

        if (!response.ok) {
            throw new Error(`Server responded with status: ${response.status}`);
        }

        const data = await response.json();
        
        // Store the session token in localStorage
        localStorage.setItem('authToken', data.token);
        
        return data.user;
    } catch (error) {
        console.error('Error verifying token:', error);
        throw error;
    }
};

// Sign in with Google
export const signInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        
        // Get the ID token
        const idToken = await result.user.getIdToken();
        
        // Verify the token with the server
        const user = await verifyToken(idToken);
        
        return user;
    } catch (error) {
        console.error('Google sign-in error:', error);
        throw error;
    }
};

// Sign in with Facebook
export const signInWithFacebook = async () => {
    try {
        const provider = new FacebookAuthProvider();
        const result = await signInWithPopup(auth, provider);
        
        // Get the ID token
        const idToken = await result.user.getIdToken();
        
        // Verify the token with the server
        const user = await verifyToken(idToken);
        
        return user;
    } catch (error) {
        console.error('Facebook sign-in error:', error);
        throw error;
    }
};

// Sign out
export const signOut = async () => {
    try {
        await firebaseSignOut(auth);
        
        // Clear the session token
        localStorage.removeItem('authToken');
        
        return true;
    } catch (error) {
        console.error('Sign out error:', error);
        throw error;
    }
};

// Get user profile
export const getUserProfile = async () => {
    try {
        const token = localStorage.getItem('authToken');
        
        if (!token) {
            throw new Error('No authentication token found');
        }
        
        const response = await fetch(`${API_URL}/profile`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            if (response.status === 401) {
                // Token is invalid or expired
                localStorage.removeItem('authToken'); // Clear the invalid token
                throw new Error('Your session has expired. Please sign in again.');
            }
            throw new Error(`Server responded with status: ${response.status}`);
        }
        
        const data = await response.json();
        return data.user;
    } catch (error) {
        console.error('Error getting user profile:', error);
        throw error;
    }
};

// Get the current authentication token
export const getAuthToken = () => {
    return localStorage.getItem('authToken');
};

// Validate if the token exists and is not expired
export const validateToken = async () => {
    try {
        const token = getAuthToken();
        
        if (!token) {
            return false;
        }
        
        // Make a lightweight request to validate the token
        const response = await fetch(`${API_URL}/profile`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        if (!response.ok) {
            if (response.status === 401) {
                // Token is invalid or expired
                localStorage.removeItem('authToken'); // Clear the invalid token
                return false;
            }
            return false;
        }
        
        return true;
    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};
