import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDn5G-Wu89zUczviOqAVKHWBLVEVQshWNI",
  authDomain: "resumer-582ae.firebaseapp.com",
  projectId: "resumer-582ae",
  storageBucket: "resumer-582ae.firebasestorage.app",
  messagingSenderId: "849401151356",
  appId: "1:849401151356:web:a01fd574bd454e7cb82ef9",
  measurementId: "G-15JRJ3D7NY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

export { auth }; 