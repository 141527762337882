
import Modal from "react-modal";
import React from "react";
export default function EncryptPasswordModal({handlePasswordSubmit, showPasswordModal, setShowPasswordModal, isDecrypting, handleSaveWithEncryption, storePassword, password, setPassword, confirmPassword, setConfirmPassword, passwordError, setPasswordError}) {
    return (
        <Modal
        isOpen={showPasswordModal}
        onRequestClose={() => {
            if (!isDecrypting) {
                setShowPasswordModal(false);
                setPassword('');
                setConfirmPassword('');
                setPasswordError('');
            }
        }}
        style={{
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '400px',
                width: '100%',
                padding: '2rem',
                borderRadius: '0.5rem'
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)'
            }
        }}
        contentLabel="Password Modal"
    >
        <div className="mb-4">
            <h2 className="text-xl font-bold mb-2">{isDecrypting ? 'Enter Password to Decrypt' : 'Encrypt Your Resume'}</h2>
            <p className="text-gray-600 mb-4">
                {isDecrypting 
                    ? 'Please enter your password to decrypt your resume data.' 
                    : 'Your resume data will be encrypted with this password. You will need this password to access your data in the future.'}
            </p>
            
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                    Password
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            
            {!isDecrypting && (
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                        Confirm Password
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="confirmPassword"
                        type="password"
                        placeholder="Confirm your password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
            )}
            
            {passwordError && (
                <div className="mb-4 text-red-500 text-sm">
                    {passwordError}
                </div>
            )}
            
            <div className="flex justify-end">
                {!isDecrypting && (
                    <button
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                        onClick={() => {
                            setShowPasswordModal(false);
                            setPassword('');
                            setConfirmPassword('');
                            setPasswordError('');
                        }}
                    >
                        Cancel
                    </button>
                )}
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handlePasswordSubmit}
                >
                    {isDecrypting ? 'Decrypt' : 'Encrypt & Save'}
                </button>
            </div>
        </div>
    </Modal>
    );
}