import Button from '@material-tailwind/react/Button';
import Image from '@material-tailwind/react/Image';
import H3 from '@material-tailwind/react/Heading3';
import Icon from '@material-tailwind/react/Icon';
import LeadText from '@material-tailwind/react/LeadText';
import ProfilePicture from 'assets/img/user.png';
import { EditText } from 'react-edit-text';
import TagsInput from 'react-tagsinput';
import { useRef, useState, useEffect } from 'react';
import 'react-tagsinput/react-tagsinput.css'
import 'assets/styles/custom.css'
import Avatar from 'react-avatar-edit';
import Header from 'components/Header';
import { useSelector } from 'react-redux'; 
// import { Modal } from '@material-tailwind/react';
import Modal from 'react-modal';
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';
import { saveResume, updateResume, getResumes, decryptResume } from '../../api/resume';
import { useParams } from 'react-router-dom';
import { encryptData, decryptData, storePassword, getStoredPassword } from 'utils/utils';
import EncryptPasswordModal from '../modals/encrypt_password_modal';


export default function Content({ initialData = null, resumeId = null }) {
    // Initialize state with initialData (if provided) or default values
    // Define default placeholder objects
    const defaultExperience = {
        "post": "Your Designation",
        "company": "Company name",
        "duration": "duration",
        "title": "title ",
        "roles": [
            "briefs about responsibility and roles",
        ]
    };
    
    const defaultQualification = {
        "course": "Bsc Comp Sci",
        "university": "Mumbai University",
        "duration": "06/2010 - 06/2013 "
    };
    
    const defaultLanguages = {
        "language": "English",
        "level": "Proficient"
    };

    // Initialize state with initialData (if provided) or default values
    const [formData, setFormData] = useState(initialData || {
        education: [defaultQualification],
        languages: [defaultLanguages],
        experience: [defaultExperience],
        personalInfo: {
            name: 'Your Name',
            title: 'Your Job Title',
            about: 'Write something about yourself',
            jobTitle: 'Sr. Software Developer (8.6 Years)',
            education: 'Mumbai University 2013',
            email: 'your-email@example.com',
            phone: '9999999999 / 8888888888',
            birthdate: '4-June-2013'
        },
        skills: ['JavaScript', 'React', 'Node.js'],
        interests: ['Coding', 'Reading', 'Travel'],
        profileImage: null,
        lastUpdated: new Date().toISOString()
    });
    
    // Setup a new resume with default values
    const setupNewResume = () => {
        console.log('Setting up new resume with default values');
        
        // Initialize with default values for a new resume
        setFormData({
            education: [defaultQualification],
            languages: [defaultLanguages],
            experience: [defaultExperience],
            personalInfo: {
                name: 'Your Name',
                title: 'Your Job Title',
                about: 'Write something about yourself',
                jobTitle: 'Sr. Software Developer (8.6 Years)',
                education: 'Mumbai University 2013',
                email: 'your-email@example.com',
                phone: '9999999999 / 8888888888',
                birthdate: '4-June-2013'
            },
            skills: ['JavaScript', 'React', 'Node.js'],
            interests: ['Coding', 'Reading', 'Travel'],
            profileImage: null,
            lastUpdated: new Date().toISOString()
        });
        
        // Set tags and interests with default values
        setTags(['JavaScript', 'React', 'Node.js']);
        setInterest(['Coding', 'Reading', 'Travel']);
        
        // Reset preview
        setPreview(null);
        
        // Show success message
        setSaveStatus('success');
        setTimeout(() => setSaveStatus(null), 3000);
    };
    
    // get resume id from url /resume/:id
    // Loading and saving state
    const [isSaving, setIsSaving] = useState(false);
    const [saveStatus, setSaveStatus] = useState(null); // null, 'success', 'error'
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isDecrypting, setIsDecrypting] = useState(false);
    
   

    // Effect to load saved data from localStorage on component mount (only if initialData is not provided)
    useEffect(() => {
        // Skip loading from localStorage if initialData was provided
        if (initialData) {
            // Set tags and interests from initialData
            if (initialData.skills && initialData.skills.length > 0) {
                setTags(initialData.skills);
            }
            if (initialData.interests && initialData.interests.length > 0) {
                setInterest(initialData.interests);
            }
            
            // Set profile image if it exists in the initialData
            if (initialData.profileImage) {
                setPreview(initialData.profileImage);
                console.log('Loaded profile image from initialData');
            }
            
            return;
        }
        
        // If resumeId is empty, set up a new resume with default values
        if (!resumeId) {
            console.log('No resumeId provided, setting up new resume with default values');
            // We'll call setupNewResume in a setTimeout to ensure it runs after the component is fully mounted
            setTimeout(() => {
                setupNewResume();
            }, 0);
            return;
        }
        
        const savedData = localStorage.getItem('resumeData');
        if (savedData) {
            try {
                // Check if data is encrypted (starts with 'U2F' for AES encryption)
                if (savedData.startsWith('U2F')) {
                    const storedPassword = getStoredPassword();
                    if (storedPassword) {
                        try {
                            const decryptedData = decryptData(savedData, storedPassword);
                            setFormData(decryptedData);
                            
                            // Set tags and interests from decrypted data
                            if (decryptedData.skills && decryptedData.skills.length > 0) {
                                setTags(decryptedData.skills);
                            }
                            if (decryptedData.interests && decryptedData.interests.length > 0) {
                                setInterest(decryptedData.interests);
                            }
                            
                            // Set profile image if it exists in the decrypted data
                            if (decryptedData.profileImage) {
                                setPreview(decryptedData.profileImage);
                                console.log('Loaded profile image from encrypted data');
                            }
                            
                            console.log('Loaded and decrypted resume data from localStorage');
                        } catch (error) {
                            console.error('Error decrypting saved resume data:', error);
                            // If decryption fails, we'll need to prompt for password
                            setIsDecrypting(true);
                            setShowPasswordModal(true);
                        }
                    } else {
                        // No stored password, need to prompt
                        setIsDecrypting(true);
                        setShowPasswordModal(true);
                    }
                } else {
                    // Data is not encrypted, just parse it
                    const parsedData = JSON.parse(savedData);
                    setFormData(parsedData);
                    
                    // Set tags and interests from parsed data
                    if (parsedData.skills && parsedData.skills.length > 0) {
                        setTags(parsedData.skills);
                    }
                    if (parsedData.interests && parsedData.interests.length > 0) {
                        setInterest(parsedData.interests);
                    }
                    
                    // Set profile image if it exists in the parsed data
                    if (parsedData.profileImage) {
                        setPreview(parsedData.profileImage);
                        console.log('Loaded profile image from localStorage');
                    }
                    
                    console.log('Loaded saved resume data from localStorage');
                }
            } catch (error) {
                console.error('Error parsing saved resume data:', error);
            }
        }
    }, [initialData]);

    // Handle text changes in any field
    const handleTextChange = (field, value, section = null, index = null) => {
        setFormData(prev => {
            let updatedData;
            
            if (section && typeof index === 'number') {
                // Update array items (education, languages, experience)
                const sectionData = [...prev[section]];
                sectionData[index] = { ...sectionData[index], [field]: value };
                updatedData = { ...prev, [section]: sectionData };
            } else if (section) {
                // Update nested objects (personalInfo)
                updatedData = { 
                    ...prev, 
                    [section]: { ...prev[section], [field]: value }
                };
            } else {
                // Update top level fields
                updatedData = { ...prev, [field]: value };
            }
            
            // Add timestamp of last update
            updatedData.lastUpdated = new Date().toISOString();
            
            // Save to localStorage on each update
            localStorage.setItem('resumeData', JSON.stringify(updatedData));
            
            return updatedData;
        });
    };

    // Handle input changes during typing (for real-time persistence)
    const handleInputChange = (field, e, section = null, index = null) => {
        const value = e.target.value;
        handleTextChange(field, value, section, index);
    };

    // Handle password submission for encryption/decryption
    const handlePasswordSubmit = () => {
        // Validate password
        if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters long');
            return;
        }

        if (!isDecrypting && password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

        setPasswordError('');

        if (isDecrypting) {
            // Try to decrypt with provided password
            try {
                const savedData = localStorage.getItem('resumeData');
                const decryptedData = decryptData(savedData, password);
                setFormData(decryptedData);
                storePassword(password);
                setShowPasswordModal(false);
                setIsDecrypting(false);
                setPassword('');
                setConfirmPassword('');
            } catch (error) {
                setPasswordError('Incorrect password. Please try again.');
            }
        } else {
            // Proceed with saving encrypted data
            storePassword(password);
            handleSaveWithEncryption(password);
            setShowPasswordModal(false);
            setPassword('');
            setConfirmPassword('');
        }
    };

    // Handle saving data to API with encryption
    const handleSaveWithEncryption = async (encryptionPassword, resumeId) => {
        try {
            setIsSaving(true);
            setSaveStatus(null);
            
            // Generate a title for the resume
            const title = formData.personalInfo.name 
                ? `${formData.personalInfo.name}'s Resume` 
                : `Resume - ${new Date().toLocaleDateString()}`;
            
            // Get the profile image (either from formData, current preview, or default image)
            let profileImageData = formData.profileImage || preview;
            
            // If no profile image is provided, use the default image
            if (!profileImageData) {
                console.log('No profile image provided, using default image');
                // We'll keep it null and let the UI handle the default image
            }
            
            // Ensure personal information is included in the data
            const dataToSave = {
                ...formData,
                personalInfo: {
                    ...formData.personalInfo,
                    // Ensure these fields are included even if they're using default values
                    email: formData.personalInfo.email || 'your-email@example.com',
                    phone: formData.personalInfo.phone || '9999999999 / 8888888888',
                    birthdate: formData.personalInfo.birthdate || '4-June-2013'
                },
                // Include the profile image as base64 data
                profileImage: profileImageData
            };
            
            console.log('Saving resume with profile image:', profileImageData ? 'Image included' : 'No image');
            
            // Save the resume to the server
            const result = await saveResume(title, dataToSave, encryptionPassword, resumeId);
            console.log('Save successful:', result);
            
            // Also save encrypted data to localStorage as a backup
            const encryptedData = encryptData(dataToSave, encryptionPassword);
            localStorage.setItem('resumeData', encryptedData);
            
            setSaveStatus('success');
            setTimeout(() => setSaveStatus(null), 3000); // Clear status after 3 seconds
            
            // If we're editing an existing resume, redirect to home after saving
            if (resumeId) {
                // window.location.href = '/';
            }
        } catch (error) {
            console.error('Error saving data:', error);
            setSaveStatus('error');
        } finally {
            setIsSaving(false);
        }
    };
    
    // Trigger password modal for saving
    const handleSave = () => {
        setIsDecrypting(false);
        let password = getStoredPassword();
        if (!password) {
            setShowPasswordModal(true);
        } else {
            handleSaveWithEncryption(password, resumeId);
        }
    };

    const Education = ({ index, data, addNewQualification, removeNewQualification }) => {
        const [showAdd, setShowAdd] = useState(false)
        return (
            <div className='px-5 py-2 hover:bg-gray-100 relative print:py-0.5 print:px-2' onMouseOver={() => setShowAdd(true)} onMouseOut={() => setShowAdd(false)}>
                <table>
                    <tr><td>
                        <EditText
                            defaultValue={data.course}
                            inputClassName='text-xl '
                            className='text-md font-semibold print:text-lg'
                            onSave={({value}) => handleTextChange('course', value, 'education', index)}
                            onChange={(e) => handleInputChange('course', e, 'education', index)}
                        />
                    </td></tr>
                    <tr><td>
                        <EditText
                            defaultValue={data.university}
                            inputClassName='text-xl '
                            className='text-md text-gray-600 print:text-base'
                            onSave={({value}) => handleTextChange('university', value, 'education', index)}
                            onChange={(e) => handleInputChange('university', e, 'education', index)}
                        />
                    </td></tr>
                    <tr><td>
                        <EditText
                            defaultValue={data.duration}
                            inputClassName='text-md '
                            className='text-md text-gray-600 italic print:text-sm'
                            onSave={({value}) => handleTextChange('duration', value, 'education', index)}
                            onChange={(e) => handleInputChange('duration', e, 'education', index)}
                        />
                    </td></tr>
                </table>
                <div className={`flex justify-end absolute right-0 top-2 ${showAdd ? `visible` : 'invisible'}`} >
                    <div className={' hover:cursor-pointer px-5'} onClick={addNewQualification}><Icon name="add" size="3xl" /></div>
                    <div className={' hover:cursor-pointer px-5'} onClick={() => removeNewQualification(index)}><Icon name="delete" size="3xl" /></div>
                </div>
            </div>
        )
    }
    const Languages = ({ index, data, addNewLanguages, removeNewLanguages }) => {
        const [showAdd, setShowAdd] = useState(false)

        return (
            <div className='px-5 py-2 hover:bg-gray-100 relative print:py-0.5 print:px-2' onMouseOver={() => setShowAdd(true)} onMouseOut={() => setShowAdd(false)}>
                <table>
                    <tr>
                        <td><EditText
                            defaultValue={data.language}
                            inputClassName='text-md '
                            className='text-sm text-gray-600 font-semibold print:text-xs'
                            onSave={({value}) => handleTextChange('language', value, 'languages', index)}
                            onChange={(e) => handleInputChange('language', e, 'languages', index)}
                        /></td>
                    </tr>
                    <tr>
                        <td><EditText
                            defaultValue={data.level}
                            inputClassName='text-md '
                            className='text-sm text-gray-600 italic print:text-xs'
                            onSave={({value}) => handleTextChange('level', value, 'languages', index)}
                            onChange={(e) => handleInputChange('level', e, 'languages', index)}
                        /></td>
                    </tr>
                </table>

                <div className={`flex justify-end absolute right-0 top-2 ${showAdd ? `visible` : 'invisible'}`} >
                    <div className={' hover:cursor-pointer px-5'} onClick={addNewLanguages}><Icon name="add" size="3xl" /></div>
                    <div className={' hover:cursor-pointer px-5'} onClick={() => { removeNewLanguages(index) }}><Icon name="delete" size="3xl" /></div>
                </div>
            </div>
        )
    }
    const WorkExperienceEntity = ({ index, data, addNewExperience, removeExperience }) => {
        const [showAdd, setShowAdd] = useState(false)
        // Use roles from formData to ensure it's always in sync
        const [roles, setRoles] = useState(data.roles || [])
        
        // Update local roles state when formData changes
        useEffect(() => {
            if (data.roles) {
                setRoles(data.roles);
            }
        }, [data.roles]);

        // Add a new role to the list
        const addNewRole = () => {
            const newRoles = [...roles, "New responsibility"];
            setRoles(newRoles);
            handleTextChange('roles', newRoles, 'experience', index);
        }
        
        // Remove a role from the list
        const removeRole = (roleIndex) => {
            console.log('Before removal - roles:', roles);
            const newRoles = [...roles];
            newRoles.splice(roleIndex, 1);
            console.log('After removal - newRoles:', newRoles);
            
            // Update both the local state and the form data
            setRoles(newRoles);
            
            // Ensure the form data is updated with the new roles
            setTimeout(() => {
                handleTextChange('roles', newRoles, 'experience', index);
                console.log('Updated form data with new roles');
            }, 0);
        }
        
        // Handle role input changes during typing
        const handleRoleChange = (i, e) => {
            const newRoles = [...roles];
            newRoles[i] = e.target.value;
            setRoles(newRoles);
            handleTextChange('roles', newRoles, 'experience', index);
        };
        
        return (
            <div className='p-2 hover:bg-gray-100 relative work-experience-item print:py-1 print:px-2'
                style={{ breakInside: 'avoid-page', pageBreakInside: 'avoid',  }}
                onMouseOver={() => setShowAdd(true)} onMouseOut={() => setShowAdd(false)}>
                {/* <p className='text-xl font-semibold'>Sr Software Developer</p> */}
                <EditText
                    defaultValue={data.post}
                    inputClassName='text-lg font-semibold print:text-base'
                    className='text-lg font-semibold print:text-base'
                    onSave={({value}) => handleTextChange('post', value, 'experience', index)}
                    onChange={(e) => handleInputChange('post', e, 'experience', index)}
                />
                {/* <p className='text-xl'>Lsinextgen</p> */}
                <EditText
                    defaultValue={data.company}
                    inputClassName='text-lg print:text-base'
                    className='text-lg print:text-base'
                    onSave={({value}) => handleTextChange('company', value, 'experience', index)}
                    onChange={(e) => handleInputChange('company', e, 'experience', index)}
                />
                {/* <p className=''><i>06/2019 - Present</i></p> */}
                <EditText
                    defaultValue={data.duration}
                    inputClassName='text-sm print:text-xs'
                    className='text-sm print:text-xs'
                    onSave={({value}) => handleTextChange('duration', value, 'experience', index)}
                    onChange={(e) => handleInputChange('duration', e, 'experience', index)}
                />
                {/* <p className='text-gray-600'>This is product based company provides software solutions to diverse community</i></p> */}
                <i> <EditText
                    defaultValue={data.title}
                    inputClassName='text-gray-600 w-full text-sm print:text-xs'
                    className='text-gray-600 text-sm print:text-xs'
                    onSave={({value}) => handleTextChange('title', value, 'experience', index)}
                    onChange={(e) => handleInputChange('title', e, 'experience', index)}
                /></i>
                <div className="mt-2 mb-4 print:mt-1 print:mb-2">
                    <div className="flex justify-between items-center mb-2 print:mb-1">
                        <h3 className="font-semibold text-gray-700 text-sm print:text-xs">Responsibilities & Roles</h3>
                        <button 
                            onClick={addNewRole}
                            className="bg-blue-500 hover:bg-blue-600 text-white rounded-full w-7 h-7 flex items-center justify-center focus:outline-none shadow-sm transition-colors duration-200 transform hover:scale-110 print:hidden"
                            title="Add new role"
                        >
                            <span className="text-lg font-bold">+</span>
                        </button>
                    </div>
                    {roles.length === 0 ? (
                        <div 
                            className="text-center py-4 px-3 text-gray-500 border border-dashed border-gray-300 rounded cursor-pointer hover:bg-gray-50 transition-colors duration-200"
                            onClick={addNewRole}
                        >
                            <div className="flex flex-col items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                <p>No roles added yet. Click here to add your first role.</p>
                            </div>
                        </div>
                    ) : (
                        <ul className="px-4 list-disc mt-2 space-y-2 print:mt-1 print:space-y-0">
                            {
                                roles.map((v, i) => {
                                    return (
                                        <li key={i} className="relative group mb-2 print:mb-0 print:py-0">
                                            <div className="flex items-center gap-2 print:gap-1">
                                                <div className="flex-grow relative">
                                                    <EditText
                                                        defaultValue={v}
                                                        inputClassName='text-gray-600 w-full pr-10 py-1 text-sm print:text-xs'
                                                        className='text-gray-600 w-full text-sm print:text-xs'
                                                        onChange={(e) => handleRoleChange(i, e)}
                                                        onSave={(data) => {
                                                            // Update the role value on save
                                                            const newRoles = [...roles];
                                                            newRoles[i] = data.value;
                                                            setRoles(newRoles);
                                                            handleTextChange('roles', newRoles, 'experience', index);
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <button 
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            console.log('Removing role at index:', i);
                                                            removeRole(i);
                                                        }}
                                                        className="flex items-center justify-center text-red-500 hover:bg-red-100 rounded-full w-6 h-6 transition-colors duration-200"
                                                        title="Remove role"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    )}
                </div>
    
    
                <div className={`flex justify-end absolute right-0 top-2 ${showAdd ? `visible` : 'invisible'}`} >
                    <div className={' hover:cursor-pointer px-5'} onClick={addNewExperience}><Icon name="add" size="3xl" /></div>
                    <div className={' hover:cursor-pointer px-5'} onClick={() => removeExperience(index)}><Icon name="delete" size="3xl" /></div>
                </div>
    
    
    
    
    
    
            </div>
        );
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    Modal.setAppElement('#root');

    // Default objects are now defined at the top of the component

    // }
    // const defaultInterest = {

    // }

    const [preview, setPreview] = useState(null)
    const [src, setSrc] = useState("")
    // Initialize formData.experience if empty
    useEffect(() => {
        if (formData.experience.length === 0) {
            setFormData(prev => ({
                ...prev,
                experience: [defaultExperience]
            }));
        }
    }, []);
    // Initialize formData.education if empty
    useEffect(() => {
        if (formData.education.length === 0) {
            setFormData(prev => ({
                ...prev,
                education: [defaultQualification]
            }));
        }
    }, []);

    // Initialize formData.languages if empty
    useEffect(() => {
        if (formData.languages.length === 0) {
            setFormData(prev => ({
                ...prev,
                languages: [defaultLanguages]
            }));
        }
    }, []);
    const [interest, setInterest] = useState(initialData?.interests || ["chess"])
    const [tags, setTags] = useState(initialData?.skills || (formData.skills && formData.skills.length > 0 ? formData.skills : ["java", "php", "golang"]))
    const handleChange = (tags) => {
        setTags(tags)
        // Update formData.skills when tags change
        setFormData(prev => {
            const updatedData = {
                ...prev,
                skills: tags,
                lastUpdated: new Date().toISOString()
            };
            
            // Save to localStorage
            localStorage.setItem('resumeData', JSON.stringify(updatedData));
            
            return updatedData;
        });
    }

    const handleChangeInterest = (interest) => {
        setInterest(interest)
        // Save interests to formData and localStorage
        setFormData(prev => {
            const updatedData = {
                ...prev,
                interests: interest,
                lastUpdated: new Date().toISOString()
            };
            
            // Save to localStorage
            localStorage.setItem('resumeData', JSON.stringify(updatedData));
            
            return updatedData;
        });
    }
    const addNewExperience = () => {
        setFormData(prev => ({
            ...prev,
            experience: [...prev.experience, defaultExperience],
            lastUpdated: new Date().toISOString()
        }));
        // Save to localStorage
        localStorage.setItem('resumeData', JSON.stringify({
            ...formData,
            experience: [...formData.experience, defaultExperience],
            lastUpdated: new Date().toISOString()
        }));
    }
    
    const removeExperience = (index) => {
        if (index !== 0) {
            const updatedExperience = [...formData.experience];
            updatedExperience.splice(index, 1);
            
            setFormData(prev => ({
                ...prev,
                experience: updatedExperience,
                lastUpdated: new Date().toISOString()
            }));
            
            // Save to localStorage
            localStorage.setItem('resumeData', JSON.stringify({
                ...formData,
                experience: updatedExperience,
                lastUpdated: new Date().toISOString()
            }));
        }
    }
    const addNewLanguages = () => {
        setFormData(prev => ({
            ...prev,
            languages: [...prev.languages, defaultLanguages],
            lastUpdated: new Date().toISOString()
        }));
        // Save to localStorage
        localStorage.setItem('resumeData', JSON.stringify({
            ...formData,
            languages: [...formData.languages, defaultLanguages],
            lastUpdated: new Date().toISOString()
        }));
    }
    
    const removeNewLanguages = (index) => {
        if (index !== 0) {
            const updatedLanguages = [...formData.languages];
            updatedLanguages.splice(index, 1);
            
            setFormData(prev => ({
                ...prev,
                languages: updatedLanguages,
                lastUpdated: new Date().toISOString()
            }));
            
            // Save to localStorage
            localStorage.setItem('resumeData', JSON.stringify({
                ...formData,
                languages: updatedLanguages,
                lastUpdated: new Date().toISOString()
            }));
        }
    }

    const addNewQualification = () => {
        setFormData(prev => ({
            ...prev,
            education: [...prev.education, defaultQualification],
            lastUpdated: new Date().toISOString()
        }));
        // Save to localStorage
        localStorage.setItem('resumeData', JSON.stringify({
            ...formData,
            education: [...formData.education, defaultQualification],
            lastUpdated: new Date().toISOString()
        }));
    }
    
    const removeNewQualification = (index) => {
        if (index !== 0) {
            const updatedEducation = [...formData.education];
            updatedEducation.splice(index, 1);
            
            setFormData(prev => ({
                ...prev,
                education: updatedEducation,
                lastUpdated: new Date().toISOString()
            }));
            
            // Save to localStorage
            localStorage.setItem('resumeData', JSON.stringify({
                ...formData,
                education: updatedEducation,
                lastUpdated: new Date().toISOString()
            }));
        }
    }

    const onClose = () => {
        setPreview(null)
    }

    const onCrop = (preview) => {
        // The preview is already a base64 data URL from the Avatar component
        setPreview(preview)
        
        // Save the cropped image to formData and localStorage
        setFormData(prev => {
            const updatedData = {
                ...prev,
                profileImage: preview, // Store the base64 image data
                lastUpdated: new Date().toISOString()
            };
            
            // Save to localStorage
            localStorage.setItem('resumeData', JSON.stringify(updatedData));
            
            return updatedData;
        });
    }

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 171680) {
            alert("File is too big!");
            elem.target.value = "";
        };
    }
    // const handleChangeInput = (tag) => {
    //     setTags({ tag })
    // }
    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    const componentRef = useRef();
    const { isAuthenticated } = useSelector((state) => state.auth);

    return (
        <section className="relative pb-16 bg-gray-100">
            <Header />  {/* Replace the existing header with this component */}
            
            <div className="container max-w-7xl px-4 mx-auto mt-16">
                <div className="flex justify-end mb-4">
                    <div className="flex items-center">
                        <ReactToPrint
                            trigger={() => <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm transition-all duration-200 mr-3'>
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                    </svg>
                                    Print Resume
                                </div>
                            </button>}
                            content={() => componentRef.current}
                            pageStyle="@page { size: A4; margin: 10mm; }"
                        />
                        {isAuthenticated && ( // Conditionally render the Save button
                            <div className="flex space-x-4">
                                <button 
                                    className='bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-sm transition-all duration-200'
                                    onClick={handleSave}
                                    disabled={isSaving}
                                >
                                    <div className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                                        </svg>
                                        {isSaving ? 'Saving...' : 'Save'}
                                    </div>
                                </button>
                                
                                {/* <button 
                                    className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow-sm transition-all duration-200'
                                    onClick={setupNewResume}
                                    disabled={isSaving}
                                >
                                    <div className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                        New Resume
                                    </div>
                                </button> */}
                            </div>
                        )}
                    </div>
                </div>
                  {/* Page title */}
                  <div className="text-center mb-8">
                        <h1 className="text-3xl font-bold text-gray-800">Your Professional Resume</h1>
                        <p className="text-gray-600">Create, customize and download your perfect resume</p>
                    </div>

                    {/* Security Banner */}
                    <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6 rounded-md shadow-sm">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-blue-800">Your data is end-to-end encrypted</h3>
                                <div className="mt-2 text-sm text-blue-700">
                                    <p>
                                        Resumer.in cannot access your resume data as we use client-side encryption with your password as the key. 
                                        Only you can encrypt and decrypt your information since only you know your password.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Password Modal */}
                    <EncryptPasswordModal handlePasswordSubmit={handlePasswordSubmit} showPasswordModal={showPasswordModal} setShowPasswordModal={setShowPasswordModal} isDecrypting={isDecrypting} handleSaveWithEncryption={handleSaveWithEncryption} storePassword={storePassword} password={password} setPassword={setPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} passwordError={passwordError} setPasswordError={setPasswordError} />

                <div ref={componentRef} className="print-container mt-6" style={{ breakInside: 'avoid-page', pageBreakInside: 'avoid' }}>
                  

                    <div id='printContainer' className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-xl shadow-md overflow-hidden">
                        <div className="px-4 ">
                            <div className="flex  ">
                                <div className="  px-8   py-8">
                                    <div className="relative">
                                        <div className="w-28 ">
                                            <Image
                                                src={formData.profileImage || preview || ProfilePicture}
                                                alt="Profile picture"
                                                raised
                                                rounded
                                                onClick={openModal}
                                            />
                                            <Modal
                                                isOpen={modalIsOpen}
                                                onAfterOpen={afterOpenModal}
                                                onRequestClose={closeModal}
                                                style={customStyles}
                                                contentLabel="Example Modal"
                                            >
                                                <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
                                                <button onClick={closeModal}>close</button>
                                                {/* <div>I am a modal</div> */}
                                                <Avatar
                                                    width={390}
                                                    height={295}
                                                    onCrop={onCrop}
                                                    onClose={onClose}
                                                    onBeforeFileLoad={onBeforeFileLoad}

                                                    src={src}
                                                />
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-left my-8">
                                    <EditText
                                        defaultValue={formData.personalInfo.name}
                                        inputClassName='text-gray-900 text-3xl font-serif font-bold leading-normal mt-0 mb-2 print:text-2xl'
                                        className='text-gray-900 text-3xl font-serif font-bold leading-normal mt-0 mb-2 print:text-2xl'
                                        onSave={({value}) => handleTextChange('name', value, 'personalInfo')}
                                        onChange={(e) => handleInputChange('name', e, 'personalInfo')}
                                    />
                                    <div className="mt-0 mb-2 text-gray-700 font-medium flex items-center  gap-2">
                                        <Icon name="place" size="xl" />
                                        <EditText
                                            defaultValue={formData.personalInfo.title}
                                            inputClassName='w-full text-sm print:text-xs'
                                            className='text-sm print:text-xs'
                                            onSave={({value}) => handleTextChange('title', value, 'personalInfo')}
                                            onChange={(e) => handleInputChange('title', e, 'personalInfo')}
                                        />
                                    </div>
                                    <div className="mb-2 text-gray-700  flex items-center  gap-2 ">
                                        <Icon name="work" size="xl" />
                                        {/* Sr. Software Developer (8.6 Years) */}
                                        <EditText
                                            defaultValue={formData.personalInfo.jobTitle || "Sr. Software Developer (8.6 Years)"}
                                            inputClassName='w-full text-sm print:text-xs'
                                            className='text-sm print:text-xs'
                                            onSave={({value}) => handleTextChange('jobTitle', value, 'personalInfo')}
                                            onChange={(e) => handleInputChange('jobTitle', e, 'personalInfo')}
                                        />
                                    </div>
                                    <div className="mb-2 text-gray-700 flex items-center  gap-2">
                                        <Icon name="account_balance" size="xl" />
                                        {/* Mumbai University 2013 */}
                                        <EditText
                                            defaultValue={formData.personalInfo.education || "Mumbai University 2013"}
                                            inputClassName='w-full'
                                            className=' text-sm print:text-xs'
                                            onSave={({value}) => handleTextChange('education', value, 'personalInfo')}
                                            onChange={(e) => handleInputChange('education', e, 'personalInfo')}
                                        />
                                    </div>
                                </div>
                                {/* <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:self-center flex justify-center mt-10 lg:justify-end lg:mt-0">
                                <Button color="lightBlue" ripple="light">
                                    Conntect
                                </Button>
                            </div> */}
                                {/* <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                    <div className="mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            22
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Friends
                                        </span>
                                    </div>
                                    <div className="mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            10
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Photos
                                        </span>
                                    </div>
                                    <div className="lg:mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            89
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Comments
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                            </div>

                            <div className='p-4 bg-gray-200 text-center'>
                                <div className='m-auto flex flex-wrap items-center justify-center gap-6'>
                                    {/* <span className='px-4'>dheerajv4855@gmail.com</span> */}
                                    <div className=" text-gray-700 font-medium flex items-center gap-2 mx-4">
                                        <Icon name="email" size="xl" color="blue" />
                                        <EditText
                                            defaultValue={formData.personalInfo.email || "your-email@example.com"}
                                            inputClassName='w-full text-sm'
                                            className='text-gray-700 text-sm print:text-xs'
                                            onChange={(e) => handleInputChange('email', e, 'personalInfo')}
                                            onSave={({value}) => handleTextChange('email', value, 'personalInfo')}
                                        />
                                    </div>
                                    <div className=" text-gray-700 font-medium flex items-center gap-2 mx-4">
                                        <Icon name="phone" size="xl" color="blue" />
                                        <EditText
                                            defaultValue={formData.personalInfo.phone || "9999999999 / 8888888888"}
                                            inputClassName='w-full text-sm'
                                            className='text-gray-700 text-sm print:text-xs'
                                            onChange={(e) => handleInputChange('phone', e, 'personalInfo')}
                                            onSave={({value}) => handleTextChange('phone', value, 'personalInfo')}
                                        />
                                    </div>
                                    <div className=" text-gray-700 font-medium flex items-center gap-2 mx-4">
                                        <Icon name="cake" size="xl" color="blue" />
                                        <EditText
                                            defaultValue={formData.personalInfo.birthdate || "4-June-2013"}
                                            inputClassName='w-full text-sm'
                                            className='text-gray-700 text-sm print:text-xs'
                                            onChange={(e) => handleInputChange('birthdate', e, 'personalInfo')}
                                            onSave={({value}) => handleTextChange('birthdate', value, 'personalInfo')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mb-10 border-t border-gray-200 print:mb-4">
                                <div className='grid grid-cols-5 gap-6 px-6 py-4 print:gap-3 print:px-3 print:py-2'>
                                    <div className='col-span-3'>
                                        <div className='text-left py-6 print:py-2 section-work-experience' style={{ breakBefore: 'auto', pageBreakBefore: 'auto' }}>
                                            <h4 className='text-xl font-bold text-gray-700 uppercase print:text-lg'>Work Experience</h4>
                                            {
                                                formData.experience.map((v, i) => {
                                                    return <WorkExperienceEntity index={i} removeExperience={removeExperience} key={i} data={v} addNewExperience={addNewExperience} />
                                                })
                                            }


                                            {/* <div className='p-5 hover:bg-gray-100'>
                                            <p className='text-xl font-semibold'>Sr Software Developer</p>
                                            <p className='text-xl'>Lsinextgen</p>
                                            <p className=''><i>06/2019 - Present</i></p>
                                            <p className='text-gray-600'><i>This is product based company provides software solutions to diverse community</i></p>
                                            <ul className="px-4 list-disc">
                                                <li>Working on new projects from scratch individually with php and laravel</li>
                                                <li>Optimized geo location based search engine with around 7m records
                                                    using meilisearch</li>
                                                <li>created api in golang for task management project</li>
                                                <li>created a multi tenant api for inventory + billlings software in golang</li>
                                            </ul>

                                        </div>
                                        <div className='p-5 hover:bg-gray-100'>
                                            <p className='text-xl font-semibold'>Sr Software Developer</p>
                                            <p className='text-xl'>Lsinextgen</p>
                                            <p className=''><i>06/2019 - Present</i></p>
                                            <p className='text-gray-600'><i>This is product based company provides software solutions to diverse community</i></p>
                                            <ul className="px-4 list-disc">
                                                <li>Working on new projects from scratch individually with php and laravel</li>
                                                <li>Optimized geo location based search engine with around 7m records
                                                    using meilisearch</li>
                                                <li>created api in golang for task management project</li>
                                                <li>created a multi tenant api for inventory + billlings software in golang</li>
                                            </ul>

                                        </div> */}
                                        </div>
                                        <div className='text-left py-6 print:py-2 section-qualification' style={{ breakBefore: 'auto', pageBreakBefore: 'auto' }}>
                                            <h4 className='text-xl font-bold text-gray-700 uppercase print:text-lg' color="gray">Qualification</h4>
                                            <div>
                                                {
                                                    formData.education.map((v, i) => {
                                                        return <Education key={i} index={i} data={v} addNewQualification={addNewQualification} removeNewQualification={removeNewQualification} />
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='text-left py-6 print:py-2 section-skills' style={{ breakBefore: 'auto', pageBreakBefore: 'auto' }}>
                                            <h4 className='text-xl font-bold text-gray-700 uppercase print:text-lg' color="gray">Skills</h4>
                                            <div style={{ breakInside: 'avoid-page', pageBreakInside: 'avoid' }}>
                                                <TagsInput value={tags} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='text-left py-6 print:py-2 section-languages' style={{ breakBefore: 'auto', pageBreakBefore: 'auto' }}>
                                            <h4 className='text-xl font-bold text-gray-700 uppercase print:text-lg' color="gray">Languages</h4>
                                            <div>
                                                {
                                                    formData.languages.map((v, i) => {
                                                        return <Languages key={i} index={i} data={v} addNewLanguages={addNewLanguages} removeNewLanguages={removeNewLanguages} />
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className='text-left py-6 print:py-2 section-interests' style={{ breakBefore: 'auto', pageBreakBefore: 'auto' }}>
                                            <h4 className='text-xl font-bold text-gray-700 uppercase print:text-lg' color="gray">Interest</h4>
                                            <div style={{ breakInside: 'avoid-page', pageBreakInside: 'avoid' }}>
                                                <TagsInput value={interest} onChange={handleChangeInterest} className="interest" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}
