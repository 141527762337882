import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import H5 from '@material-tailwind/react/Heading5';
import InputIcon from '@material-tailwind/react/InputIcon';
import Checkbox from '@material-tailwind/react/Checkbox';
import Button from '@material-tailwind/react/Button';
import Page from 'components/login/Page';
import Container from 'components/login/Container';
import { useDispatch } from 'react-redux';
import { setUser } from '../features/authSlice';
import { signInWithGoogle, signInWithFacebook } from '../api/auth';

export default function Login() {
    const [error, setError] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    
    const handleGoogleSignIn = async () => {
        try {
            const user = await signInWithGoogle();
            dispatch(setUser({
                id: user.id,
                email: user.email,
                displayName: user.displayName,
                photoURL: user.photoURL,
                uid: user.uid,
            }));
            history.push('/dashboard');
        } catch (error) {
            console.error("Google sign-in error:", error);
            setError("Failed to sign in with Google. Please try again.");
        }
    };

    const handleFacebookSignIn = async () => {
        try {
            const user = await signInWithFacebook();
            dispatch(setUser({
                id: user.id,
                email: user.email,
                displayName: user.displayName,
                photoURL: user.photoURL,
                uid: user.uid,
            }));
            history.push('/dashboard');
        } catch (error) {
            console.error("Facebook sign-in error:", error);
            setError("Failed to sign in with Facebook. Please try again.");
        }
    };
    
    return (
        <Page>
            {/* <div className="absolute top-4 left-4">
                <Link to="/">
                    <h1 className="text-2xl font-bold text-blue-700">Resumer</h1>
                </Link>
            </div> */}
            
            <Container>
                <Card className="shadow-lg rounded-xl">
                    <CardHeader color="blue" className="relative h-28 flex items-center justify-center">
                        <div className="text-center">
                            <H5 color="white" style={{ marginBottom: 0 }}>
                                Welcome Back
                            </H5>
                            <p className="text-white text-sm mt-1">Sign in to your account</p>
                        </div>
                    </CardHeader>

                    <CardBody className="px-8 py-8">
                        <div className="mb-8">
                            <InputIcon
                                type="email"
                                color="lightBlue"
                                placeholder="Email Address"
                                iconName="email"
                                outline={true}
                            />
                        </div>
                        <div className="mb-4">
                            <InputIcon
                                type="password"
                                color="lightBlue"
                                placeholder="Password"
                                iconName="lock"
                                outline={true}
                            />
                        </div>
                        <div className="flex items-center justify-between mb-4">
                            <Checkbox
                                color="lightBlue"
                                text="Remember Me"
                                id="remember"
                            />
                            <Link to="/forgot-password" className="text-sm text-blue-500 hover:text-blue-700">
                                Forgot Password?
                            </Link>
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0 px-8 pb-8">
                        <Button
                            color="blue"
                            buttonType="filled"
                            size="lg"
                            ripple="light"
                            block={true}
                            className="mt-2"
                        >
                            Sign In
                        </Button>
                        
                        <div className="text-center my-4">
                            <p className="text-gray-600">Or sign in with</p>
                        </div>
                        
                        <div className="flex justify-center gap-2 mb-4">
                            <Button
                                color="red"
                                buttonType="outline"
                                size="lg"
                                ripple="light"
                                className="flex items-center"
                                onClick={handleGoogleSignIn}
                            >
                                <i className="fab fa-google mr-2"></i> Google
                            </Button>
                            <Button
                                color="blue"
                                buttonType="outline"
                                size="lg"
                                ripple="light"
                                className="flex items-center"
                                onClick={handleFacebookSignIn}
                            >
                                <i className="fab fa-facebook-f mr-2"></i> Facebook
                            </Button>
                        </div>
                        
                        {error && (
                            <div className="text-center text-red-500 mb-4">
                                {error}
                            </div>
                        )}
                        
                        <div className="text-center mt-6">
                            <p className="text-gray-600">
                                Don't have an account?{' '}
                                <Link to="/register" className="text-blue-500 font-medium hover:text-blue-700">
                                    Sign Up
                                </Link>
                            </p>
                        </div>
                    </CardFooter>
                </Card>
            </Container>
        </Page>
    );
}
