import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Content from 'components/profile/Content';
import Modal from 'react-modal';
import { getResumeById } from '../api/resume';
import CryptoJS from 'crypto-js';

Modal.setAppElement('#root');

export default function Profile() {
    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(id ? true : false);
    const [resume, setResume] = useState(null);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [decryptedData, setDecryptedData] = useState(null);
    
    // Fetch the specific resume if an ID is provided
    useEffect(() => {
        if (id) {
            fetchResume();
        }
    }, [id]);
    
    // Store password securely (encoded, not encrypted) in localStorage
    const storePassword = (resumeId, password) => {
        try {
            // Simple encoding - not secure for production but meets requirements
            const encodedPassword = btoa(password);
            
            // Store passwords by resume ID
            const storedPasswords = JSON.parse(localStorage.getItem('resumePasswords') || '{}');
            storedPasswords[resumeId] = encodedPassword;
            
            localStorage.setItem('resumePasswords', JSON.stringify(storedPasswords));
            return true;
        } catch (error) {
            console.error('Error storing password:', error);
            return false;
        }
    };
    
    // Retrieve stored password for a specific resume
    const getStoredPassword = (resumeId) => {
        try {
            const storedPasswords = JSON.parse(localStorage.getItem('resumePasswords') || '{}');
            const encodedPassword = storedPasswords[resumeId];
            
            if (encodedPassword) {
                return atob(encodedPassword);
            }
            return null;
        } catch (error) {
            console.error('Error retrieving password:', error);
            return null;
        }
    };
    
    const fetchResume = async () => {
        try {
            setLoading(true);
            const data = await getResumeById(id);
            
            if (data && data.resume) {
                setResume(data.resume);
                
                // Check if we have a stored password for this resume
                const storedPassword = getStoredPassword(id);
                
                if (storedPassword) {
                    // Try to decrypt with the stored password
                    try {
                        const decrypted = decryptResume(data.resume.encryptedData, storedPassword);
                        setDecryptedData(decrypted);
                        setPassword(storedPassword); // Keep the password in state for potential re-use
                        // No need to show password modal
                    } catch (error) {
                        console.error('Stored password failed to decrypt:', error);
                        // If stored password fails, show the password modal
                        setShowPasswordModal(true);
                    }
                } else {
                    // No stored password, show the password modal
                    setShowPasswordModal(true);
                }
            } else {
                // Resume not found, redirect to home
                history.push('/');
                alert('Resume not found');
            }
        } catch (error) {
            console.error('Error fetching resume:', error);
            history.push('/');
            alert('Error loading resume. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    // Decrypt the resume data with the provided password
    const decryptResume = (encryptedData, password) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, password);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedData;
        } catch (error) {
            console.error('Decryption error:', error);
            throw new Error('Failed to decrypt data. Incorrect password.');
        }
    };
    
    // Handle password submission
    const handlePasswordSubmit = () => {
        if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters long');
            return;
        }
        
        setPasswordError('');
        
        try {
            // Try to decrypt with provided password
            const decrypted = decryptResume(resume.encryptedData, password);
            setDecryptedData(decrypted);
            setShowPasswordModal(false);
            
            // Store password securely for this specific resume
            storePassword(id, password);
            
            // Also save encrypted data to localStorage as a backup
            localStorage.setItem('resumeData', resume.encryptedData);
        } catch (error) {
            setPasswordError('Incorrect password. Please try again.');
        }
    };
    
    // If loading, show a loading spinner
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen bg-gray-100">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }
    
    return (
        <>
            <main>
                {/* Password Modal */}
                <Modal
                    isOpen={showPasswordModal}
                    onRequestClose={() => {
                        // If no decrypted data yet, go back to home
                        if (!decryptedData && id) {
                            history.push('/');
                        }
                        setShowPasswordModal(false);
                    }}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            maxWidth: '400px',
                            width: '100%',
                            padding: '2rem',
                            borderRadius: '0.5rem'
                        },
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.75)'
                        }
                    }}
                    contentLabel="Password Modal"
                >
                    <div className="mb-4">
                        <h2 className="text-xl font-bold mb-2">Enter Password to Decrypt</h2>
                        <p className="text-gray-600 mb-4">
                            Please enter your password to decrypt your resume data.
                        </p>
                        
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                Password
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="password"
                                type="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handlePasswordSubmit()}
                            />
                        </div>
                        
                        {passwordError && (
                            <div className="mb-4 text-red-500 text-sm">
                                {passwordError}
                            </div>
                        )}
                        
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                                onClick={() => {
                                    if (id) {
                                        history.push('/');
                                    }
                                    setShowPasswordModal(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={handlePasswordSubmit}
                            >
                                Decrypt
                            </button>
                        </div>
                    </div>
                </Modal>
                
                {/* Pass the decrypted data or null to Content */}
                <Content initialData={decryptedData} resumeId={id} />
            </main>
            <a href="https://www.flaticon.com/free-icons/user" title="user icons" className="text-xs text-gray-500 text-center block mt-4">User icons created by Smashicons - Flaticon</a>
        </>
    );
}
